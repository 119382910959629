<template>
  <div id="footer">
    <div class="footer_con">
     <!-- <div class="jvz_center_mean footer_title">
        <div class="jvz_center">
          <div><img src="../assets/image/footer5.png" alt="" /></div>
          <div class="footer_img">
            <p style="font-size: 17px; font-weight: bold">遵循认知</p>
            <p>融合国际先进教育理念</p>
          </div>
        </div>
        <div class="jvz_center">
          <div><img src="../assets/image/footer4.png" alt="" /></div>

          <div class="footer_img">
            <p style="font-size: 17px; font-weight: bold">遵循认知</p>
            <p>融合国际先进教育理念</p>
          </div>
        </div>
      </div> -->
      <div class="footer_list">
        <div style="width: 30%;">
          <img style="width:210px;height:65px" src="../assets/image/logo.png" alt="">
        </div>
        <div  style="width: 45%;">
          <span class="footer_tit ">联系我们</span>
          <ul>
            <li>电话：<span style="color:#FC8400 ">010-64813941</span>（周一 至 周五 9:00 - 17:00）</li>
            <li>
              邮箱：gmtthse@126.com
            </li>
            <li>地址：北京市朝阳区利泽中2路1号1号楼3层315室</li>

          </ul>
        </div>
      <!--  <div style="width: 25%;">
          <span class="footer_tit">关注我们</span>
          <ul class="tit_lx">
            <li>
              <img style="width:48px;height:48px;margin-right: 20px;" src="../assets/image/footer_wx.png" alt="" />
              <img style="width:48px;height:48px;" src="../assets/image/footer_dy.png" alt="" />
            </li>
          </ul>
        </div> -->
        <div style="width: 25%;">
          <span class="footer_tit">友情链接</span>
          <ul>
            <li><a href="https://www.mem.gov.cn/" target="_blank">应急管理部</a></li>
            <li><a href="http://www.chemicalsafety.org.cn/" target="_blank">中国化学品安全协会</a></li>
            <li><a href="http://www.ttcheer.com/" target="_blank">北京天泰志远科技股份有限公司</a></li>

       <!--     <li>地方应急管理局</li>
            <li>国家标准全文公开系统</li> -->

          </ul>
        </div>
      </div>
    </div>
    <div class="bottom_d">
      高名安全 版权所有 Copyright©2018-2022 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      备案号：京ICP备2020037393号-1
    </div>
  </div>
</template>
<script>
export default {
  inject:["reload"],
  data() {
    return {};
  },
  methods:{
    // push() {
    //   this.$router.push({path: "/about",});
    //   sessionStorage.setItem("top_index",3);
    //   this.reload()
    // },
  }
};
</script>
<style scoped lang="less">
a{
  color: #999999;
  font-size: 14px;
}
a:hover{
  color: rgb(233,83,25);
}
.a_color:hover{
  color: rgb(233,83,25);
}
#footer {
  background: white;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  min-width: 1200px;
  margin-top: 80px;
  .footer_con {
    width: 1200px;
    margin: 0 auto;
    .footer_title {
      height: 120px;

      border-bottom: solid 2px #f4f4f4;
      .footer_img {
        display: flex;
        margin-left: 30px;
        height: 50px;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .footer_list {
      display: flex;
      padding: 30px;
      position: relative;
      justify-content: space-around;
      align-items: center;
      div{
        width: 25%;
      }
      img {
        width: 20px;
      }
      > div {
        color: #999999;

        .footer_tit {
          font-size: 15px;
          font-weight: bold;
          color: #333;
        }
        ul {
          margin-top: 10px;
          li {
            display: flex;
            font-size: 14px;
            line-height: 25px;
          }
        }
        .tit_lx li {
          line-height: 30px;
          display: flex;
          align-items: center;
        }
      }
      .footer_ewm {
        width: 100px;
        position: absolute;
        right: 0;
        img {
          width: 100%;
        }
      }
    }
  }
  .bottom_d {
    text-align: center;
    background: #f4f5f7;
    font-size: 13px;
    color: #A0A0A2;
    line-height: 50px;
  }
}
</style>
