import Vue from 'vue'
import { Button,dialog,form,formItem,input,
carousel,
carouselItem,
image,
tooltip,
checkbox,
checkboxGroup,
row,
col,
pagination,
breadcrumb,
breadcrumbItem,
tabs,
tabPane,
rate,
collapse,
badge,
Radio,
RadioGroup,
CollapseItem,
table,
TableColumn,
dropdown,
dropdownMenu,
dropdownItem,
popover,
Loading
} from 'element-ui'
Vue.use(table)
Vue.use(Loading)
Vue.use(TableColumn)
Vue.use(RadioGroup)
Vue.use(popover)

Vue.use(Button)
Vue.use(dialog)
Vue.use(form)
Vue.use(formItem)
Vue.use(input)
Vue.use(carousel)
Vue.use(carouselItem)
Vue.use(image)
Vue.use(checkbox)
Vue.use(tooltip)
Vue.use(pagination)
Vue.use(rate)
Vue.use(Radio)


Vue.use(badge)

Vue.use(breadcrumb)
Vue.use(breadcrumbItem)
Vue.use(row)
Vue.use(col)
Vue.use(checkboxGroup)
Vue.use(tabs)
Vue.use(tabPane)
Vue.use(collapse)
Vue.use(CollapseItem)
Vue.use(dropdownMenu)
Vue.use(dropdownItem)
Vue.use(dropdown)
 