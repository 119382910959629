<template>
  <div id="logon">
    <!-- <el-button type="text" @click="dialogFormVisible = true"
      >打开嵌套表单的 Dialog</el-button
    > -->
<!-- :close-on-click-modal="false" -->
    <el-dialog

      v-if="logon_register == '登录'"
      width="600px"
      custom-class="logon_class jvz_center"
      top="15vh"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :visible.sync="dialogFormVisible"
    >
      <div class="logon_logo">
        <img  style="width: 170px;height: 50px;object-fit: fill;" src="../assets/image/logo.png" alt="" />
      </div>

      <div class="logon_title jvz_center">
        <span @click="logon_type(1)">账号登录</span>
        <span @click="logon_type(2)">手机号登录</span>
      </div>
      <div class="logon_border">
        <div :class="dialogtype == 1 ? 'logon_border_type' : ''"></div>
        <div :class="dialogtype == 2 ? 'logon_border_type' : ''"></div>
      </div>

      <!-- 账号登录 -->
      <el-form
        :rules="rules"
        ref="ruleForm"
        v-if="dialogtype == 1"
        :model="form"
        style="text-align: center"
      >
        <el-form-item class="logon_input" prop="username">
          <el-input
            class="logon_input"
            v-model="form.username"
            placeholder="请输入账号"
            autocomplete="new-password"

          >
          </el-input>
        </el-form-item>
        <el-form-item
          class="logon_input"
          style="margin-top: 20px"
          prop="password"
        >
          <el-input
            class="logon_input"
            v-model="form.password"
            placeholder="请输入密码"
            autocomplete="off"

            show-password
          >
          </el-input>
        </el-form-item>
      </el-form>

      <!-- 手机号登录 -->
      <el-form
        :rules="rules_s"
        ref="ruleForm_s"
        v-if="dialogtype == 2"
        :model="form_y"
        style="text-align: center"
      >
        <el-form-item class="logon_input" prop="uname_s">
          <el-input v-model="form_y.uname_s" placeholder="请输入手机号">
          </el-input>
        </el-form-item>
        <div style="position: relative">
          <el-form-item
            class="logon_input"
            style="margin-top: 20px"
            prop="pasword_s"
          >
            <el-input v-model="form_y.pasword_s" placeholder="请输入验证码">
            </el-input>
            <el-button
              style="
                position: absolute;
                transform: translate(-81px, 0px);
                color: #ea551a;
              "
              :disabled="disabled"
              @click="logon_yzm"
              type="text"
              >{{ checking_value }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>

      <div class="logon_dl_btn jvz_center" @click="logon_dl">
        <span>登录</span>
      </div>
      <div class="logon_dl_txt jvz_center_left_right">
        <div v-if="dialogtype == 1">
          <el-checkbox v-model="checked"></el-checkbox>
          <span>&nbsp;记住密码</span> |

          <span @click="register('忘记密码')">忘记密码？</span>
        </div>
        <div>
          <span @click="register('注册')">注册账号？</span>
        </div>
      </div>

      <!-- <div class="logon_dl jvz_center_left_right">
        <div class="jvz_center logon_dl_sm">
          <div><img src="../assets/image/logo_ewm.png" alt="" /></div>
          <span>扫码登录</span>
        </div>
        <div class="jvz_center logon_dl_sm">
          <div><img src="../assets/image/logo_wx.png" alt="" /></div>
          <span>微信登录</span>
        </div>
      </div> -->
      <!-- <div class="logon_dl_bottom jvz_center">
        <div>
          <el-checkbox
            style="margin-left: -16px"
            v-model="checked_yd"
          ></el-checkbox>
          <span >
            我已阅读并接受 <span @click="urlxy">《注册声明》</span>  <span @click="urlxyw">《隐私政策》</span>
          </span>
        </div>
      </div> -->

      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div> -->
    </el-dialog>

    <!-- 注册账号 -->
    <reg-Ister v-if="logon_register == '注册'"  @zdy-text="hanszdle"></reg-Ister>
    <!-- 忘记密码 -->
    <For-getPas v-if="logon_register == '忘记密码'" @zdy-text="hanszdle"></For-getPas>
  </div>
</template>
<script>
import md5 from "js-md5";
import register from "./register.vue"; //注册
import ForgetPas from "./ForgetPas.vue"; //忘记密码
import router from "@/store/index"; //路由
import { Message } from "element-ui"
export default {
  data() {
    //手机号 自定义验证规则  //验证手机号是否注册
    var checkAge = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
      } else if (!/^1(3|4|5|7|8|9)\d{9}$/.test(value)) {
        callback(new Error("请填写正确的手机号"));
      } else {
        //验证手机号 是否注册
        this.$axios
          .get(
            "/api/blade-auth/oauth/check-phone" +
              "?phone=" +
              this.form_y.uname_s
          )
          .then((res) => {
            console.log(res);
            if (res.isExist==true) {
              callback();
              // this.disabled = false;

            } else {
              callback(new Error("手机号码未注册"));
              // this.disabled = true; //禁用获取验证码
            }
          });
      }
    };
    return {
      dialogtype: 1, //账号 手机号 登录 状态
      logon_register: "", //登录注册 切换
      dialogFormVisible: false,
      checking_value: "获取验证码",
      disabled: false, //验证码 按钮禁用
      countdown:null,
      //账号登录
      form: {
        username: "",
        password: "",
      },
      //手机号登录
      form_y: {
        uname_s: "",
        pasword_s: "",
      },
      checked: false, //记住密码
      checked_yd: true, //阅读协议

      //账号密码登录验证规则
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      //手机号登录验证规则
      rules_s: {
        uname_s: [{ validator: checkAge, trigger: "blur" }],
        pasword_s: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    "reg-Ister": register, //注册组件
    "For-getPas": ForgetPas, //忘记密码
  },
  mounted() {

    if (this.typeshow) {
      this.logon_register = this.typeshow;
    }
    this.form.username=sessionStorage.getItem("username");
    this.form.password=sessionStorage.getItem("password");
    console.log(this.form)
  },
  methods: {
    //注册返回
    hanszdle(){
     this.logon_register ='登录'
    },
    //打开登录敞口
    open() {
      console.log(this.typeshow);

      this.dialogFormVisible = true;

      this.$nextTick((_) => {
        this.$forceUpdate();
      });
    },

    //openzc打开注册窗口
    openzc() {
      this.dialogFormVisible = true;

      this.logon_register = "注册";
      this.$nextTick((_) => {
        this.$forceUpdate();
      });
    },

    //登录弹窗关闭
    handleClose(done) {
      this.dialogFormVisible = false;
      // this.$router.push("/home");
      // this.$nextTick((_) => {
      //   this.$forceUpdate();
      // });

    },
    //跳转协议页面
    urlxy() {
      this.dialogFormVisible = false;

      this.$router.push("/xy");
    },
    urlxyw(){
 this.dialogFormVisible = false;

      this.$router.push("/agreement");
    },
    //登录注册切换
    register(type) {
      this.logon_register = type;
      console.log(this.logon_register);
    },
    //密码登录 手机登录 状态切换 验证效果取消
    logon_type(type_i) {
      if (this.dialogtype !== type_i) {
        this.dialogtype = type_i;
        if (this.dialogtype == 1) {
          this.$refs["ruleForm_s"].clearValidate();
        }
        if (this.dialogtype == 2) {
          this.$refs["ruleForm"].clearValidate();
        }
      }
    },

    //登录  //先验证表单
    logon_dl() {

      //账号登录验证
      if (this.dialogtype == 1) {
        this.$refs.ruleForm.validate((boolean, object) => {
          if (boolean) {
            this.submit_zh(); //验证通过
          } else {
            console.log("没通过");
            return false;
          }
        });
      }
      //手机号登录验证
      if (this.dialogtype == 2) {
        this.$refs.ruleForm_s.validate((boolean, object) => {
          if (boolean) {
            this.submit_sj();
          } else {
            console.log("没通过");
            return false;
          }
        });
      }
    },

    //账号密码验证通过  登录
    submit_zh() {
      console.log(this.checked)
      if(this.checked==true){
        sessionStorage.setItem("username", this.form.username);
        sessionStorage.setItem("password", this.form.password);
      }else{
        sessionStorage.setItem("username", '');
        sessionStorage.setItem("password", '');
      }
      var password = md5(this.form.password); //需要加密的md5密码
      this.$axios
        .post(
          "/api/blade-auth/oauth/token",
          this.$qs.stringify({
            username: this.form.username, //
            tenantId: "000000",
            password: password,
            grant_type: "password",
            scope: "all",
          })
        )
        .then((ret) => {
          console.log(ret);
          sessionStorage.setItem("role_id", ret.role_id); //把token 存本地
          sessionStorage.setItem("token", ret.access_token); //把token 存本地
          sessionStorage.setItem("user_name", ret.user_name); //把token 存本地
          sessionStorage.setItem("user_id", ret.user_id); //把token 存本地

          // this.dialogFormVisible = false;

          if (ret.access_token) {
            console.log(location);
            console.log(this);
            // sessionStorage.setItem("top_index", 0);
            // this.$router.push("/home");
            // this.$router.go(-1)
            location.reload();
          }
        })
        .catch(function (err) {
          //  location.reload();

          console.log(err.response);
          if (err.response.status !== 200) {
            Message({
              message: err.response.data.error_description,
              type: "error"
            });
          }
        });
    },

    //手机号加验证码 登录
    submit_sj() {
      this.$axios
        .post(
          "/api/blade-auth/oauth/token",
          this.$qs.stringify({
            grant_type: "captcha", //
            tenantId: "000000",
            phone: this.form_y.uname_s,
            code: this.form_y.pasword_s,
            type: "phone",
            scope: "all",
          })
        )
        .then((ret) => {
          sessionStorage.setItem("role_id", ret.role_id); //把token 存本地

          sessionStorage.setItem("token", ret.access_token); //把token 存本地
          sessionStorage.setItem("user_name", ret.user_name); //把token 存本地
          sessionStorage.setItem("user_id", ret.user_id); //把token 存本地

          this.dialogFormVisible = false;

          if (ret.access_token) {
            this.$router.push("/home");

            sessionStorage.setItem("top_index", 0);

            location.reload();
          }
        })
        .catch(function (err) {
          console.log(err.response);
          if (err.response.status !== 200) {
            Message({
              message: err.response.data.error_description,
              type: "error"
            });
          }
        });
    },

    //获取验证码
    logon_yzm() {
      //先验证手机号
      this.$refs["ruleForm_s"].validateField(["uname_s"], (errorMsg) => {
        if (!errorMsg) {
          this.disabled = true; //禁用获取验证码
          clearInterval(this.countdown);
          this.doLoop(59);


          this.$axios
            .get(
              "/api/blade-user/mobile/captcha" + "?phone=" + this.form_y.uname_s
            )
            .then((res) => {
              console.log(res);
                    //验证通过  验证码倒计时
            });
        }
      });
    },

    //手机验证码的倒计时
    doLoop: function (seconds) {


      seconds = seconds ? seconds : 59;
      this.checking_value = seconds + "s后获取";
      // this.code = code

       this.countdown = setInterval(() => {

          console.log(seconds)

        if (seconds > 0) {
          this.checking_value = seconds + "s后获取";
          --seconds;
        } else {
         this.checking_value = "获取验证码";
          this.disabled = false; //开启
          clearInterval(this.countdown);
        }


      }, 1000);



    },
  },
};
</script>
<style scoped lang="less">
.logon_class {
  height: 540px;
  display: none;
}
#logon {
  .logon_logo {
    width: 164px;
    height: 51px;
    margin: 0 auto;
  }
  .logon_title {
    font-size: 16px;
    margin-top: 29px;
    cursor: pointer;

    span:nth-child(2) {
      margin-left: 40px;
    }
  }
  .logon_border {
    width: 216px;
    display: flex;
    background: #bfbfbf;
    height: 2px;

    margin: 15px auto 20px auto;
    div {
      width: 50%;
    }

    .logon_border_type {
      background: #ea551a;
    }
  }

  .logon_input {
    width: 380px;
    height: 40px;
    margin: 0 auto;
  }
  .logon_dl_btn {
    width: 380px;
    height: 50px;
    margin: 0 auto;
    margin-top: 20px;
    color: white;
    border-radius: 5px;
    background: #ea551a;
    cursor: pointer;
  }
  .logon_dl_txt {
    width: 380px;
    margin: 0 auto;
    margin: 11px auto 28px auto;
    div {
      span{
        cursor: pointer;
      }
      a {
        color: #606266;
      }
    }
  }
  .logon_dl {
    width: 335px;
    margin: 0 auto;

    .logon_dl_sm {
      > div {
        width: 48px;
        height: 48px;
      }
      span {
        margin-left: 10px;
        font-size: 17px;
      }
    }
  }
  .logon_dl_bottom {
    width: 100%;
    height: 60px;
    background: #f5f5f5;
    margin-top: 28px;
    a {
      color: #606266;
    }
  }
}
</style>
