
 //公用的登录弹窗


import Vue from "vue";
import login from '@/components/logon.vue' // 引入弹窗组件

import router from '../router/index'


const LoginDialog = Vue.extend(login);

login.install = function(data,type) {
 
   console.log(data)
   console.log(type)

  let instance = new LoginDialog({
    data,
    router
  }).$mount();

  document.body.appendChild(instance.$el);

  Vue.nextTick(() => {
    instance.open();
  });
};
//////////////////
login.installzc = function(data,type) {
 
  let instance = new LoginDialog({
    data,
      router
  }).$mount();

  document.body.appendChild(instance.$el);

  Vue.nextTick(() => {
    instance.openzc();
  });
};

 


export default {login}; // 导出