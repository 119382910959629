import { render, staticRenderFns } from "./_base-to-top.vue?vue&type=template&id=258a6414&scoped=true&"
import script from "./_base-to-top.vue?vue&type=script&lang=js&"
export * from "./_base-to-top.vue?vue&type=script&lang=js&"
import style0 from "./_base-to-top.vue?vue&type=style&index=0&id=258a6414&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "258a6414",
  null
  
)

export default component.exports