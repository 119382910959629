import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import login from '@/components/logon.vue' // 引入弹窗组件

Vue.use(VueRouter)

//路由重复点击 报错解决
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,  //公用头部底部主体
    redirect: "/home", // 重定向到首页
    children: [
      {
        path: '/home',  //首页
        name: 'home',
        meta: {
          requireAuth: false,  // 添加该字段，表示true进入这个路由是需要登录的
          keepAlive:true,
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
      },
      {
        path: '/about',  //关于我们
        name: 'about',
        meta: {
          requireAuth: false,  // 添加该字段，表示true进入这个路由是需要登录的
          keepAlive:true,
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
      },
      {
        path: '/homeDetail',  //首页轮播详情
        name: 'homeDetail',
        meta: {
          requireAuth: false,  // 添加该字段，表示true进入这个路由是需要登录的
          keepAlive:true,
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/homeDetail.vue')
      },
      {
        path: '/CourseCs',  //课程超市
        name: 'CourseCs',
        meta: {
          requireAuth: false,  // 添加该字段，表示true进入这个路由是需要登录的
          // keepAlive:true,
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CourseCs/CourseCs.vue')
      },
      {
        path: '/couresdetails',  //课程超市详情
        name: 'couresdetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CourseCs/couresdetails.vue')
      },
      {
        path: '/couresdetailskz',  //交易快照
        name: 'couresdetailskz',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CourseCs/couresdetailskz.vue')
      },
      {
        path: '/taskrw',  // 个人中心下 我的任务
        name: 'taskrw',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/taskrw/taskrw.vue')
      },
      {
        path: '/ShoppingCart',  //购物车
        name: 'ShoppingCart',
        meta: {
          requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ShoppingCart/ShoppingCart.vue')
      },
      {
        path: '/Learning',  //学习中心
        name: 'Learning',
        meta: {
          requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Learning/Learning.vue')
      },
      {
        path: '/collect',  //学习中心
        name: '我的收藏',
        meta: {
          requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Learning/collect.vue')
      },
      {
        path: '/paymentZF',  //提交订单
        name: 'paymentZF',
        meta: {
          requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/paymentZF/paymentZF.vue')
      },
      {
        path: '/paymentdetail',  //提交订单
        name: 'paymentdetail',
        meta: {
          requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/paymentZF/paymentdetail.vue')
      },
      {
        path: '/barcode',  //扫码支付
        name: 'barcode',
        meta: {
          requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/paymentZF/barcode.vue')
      },
      {
        path: '/exam',  //考试
        name: 'exam',
        meta: {
          requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Learning/exam.vue')
      },
      {
        path: '/score',  //成绩
        name: 'score',
        meta: {
          requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Learning/score.vue')
      },
      {
        path: '/contactUs',  //联系我们
        name: 'contactUs',
        meta: {
          requireAuth: false,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/contactUs.vue')
      },
      {
        path: '/lecturer',  //讲师
        name: '讲师详情',
        meta: {
          requireAuth: false,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/lecturer.vue')
      },
      {
        path: '/news',  //讲师
        name: '新闻公告',
        meta: {
          requireAuth: false,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/news.vue')
      },
      {
        path: '/zhib',  //直播
        name: 'zhib',
        meta: {
          // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/zhib/zhib.vue')
      },
      {
        path: '/personalCenter',  //个人中心
        name: 'personalCenter',
        meta: {
          requireAuth: true,  // 添加该字段，表示true进入这个路由是需要登录的
      },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PersonalCenter/personalCenter.vue')
      },

      {
        path: '/zfcg',
        name: 'zfcg',
            component: () => import(/* webpackChunkName: "about" */ '@/views/paymentZF/zfcallback.vue')
      },



      // {
      //   path: '/register',  //注册
      //   name: 'register',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../components/register.vue')
      // }
    ],

  },
  {
    path: '/Privacy',
    name: 'Privacy',
        component: () => import(/* webpackChunkName: "about" */ '../components/Privacy.vue')
  },
  {
    path: '/statement',
    name: 'statement',
        component: () => import(/* webpackChunkName: "about" */ '../components/statement.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
        component: () => import(/* webpackChunkName: "about" */ '../components/agreement.vue')
  },

  {
    path: '/paymentphone',  //h5支付页
    name: 'paymentphone',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/paymentZF/paymentphone.vue')
  },
  {
    path: '/sc',  //h5 上传
    name: 'sc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/shangca.vue')
  },

  // {
  //   //按需加载
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  //跳转页面，记录滚动的位置，返回所在位置
  scrollBehavior(to, from, savedPosition) {
    // console.log(savedPosition)
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },


})





router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
      if (sessionStorage.getItem('token')) {
          next();
      }
      else {
        login.install({typeshow:'登录'});
      }
  }
  else {
      next();
  }
})


export default router
