<template>
  <div id="logon">
    <el-button type="text" @click="dialogFormVisible = true"
      >打开嵌套表单的 Dialog</el-button
    >

    <el-dialog
      width="600px"
      custom-class="logon_class jvz_center"
      top="15vh"
      :visible.sync="dialogFormVisible"
			:close-on-click-modal="false"
    >
      <div class="logon_logo">
        <img src="../assets/image/logo_dl.png" alt="" />
      </div>

      <div class="logon_title jvz_center">
        <span @click="logon_type(1)">忘记密码</span>
        <!-- <span @click="logon_type(2)">填写账号信息</span> -->
      </div>
      <div class="logon_border">
        <div :class="dialogtype == 1 ? 'logon_border_type' : ''"></div>
        <!-- <div :class="dialogtype == 2 ? 'logon_border_type' : ''"></div> -->
      </div>

      <!-- 手机号验证 -->

      <el-form
        :rules="rules_s"
        ref="ruleForm_s"
        v-if="dialogtype == 1"
        :model="form_y"
        style="text-align: center"
      >
        <el-form-item class="logon_input" prop="uname_s">
          <el-input v-model="form_y.uname_s" placeholder="请输入手机号">
          </el-input>
        </el-form-item>
        <div style="position: relative">
          <el-form-item
            class="logon_input"
            style="margin-top: 16px"
            prop="pasword_s"
          >
            <el-input v-model="form_y.pasword_s" placeholder="请输入验证码">
            </el-input>
            <el-button
              style="
                position: absolute;
                transform: translate(-81px, 0px);
                color: #ea551a;
              "
              :disabled="disabled"
              @click="logon_yzm"
              type="text"
              >{{ checking_value }}
            </el-button>
          </el-form-item>
        </div>
        <!-- <el-form-item
          style="margin-top: 16px"
          class="logon_input"
          prop="username"
        >
          <el-input
            class="logon_input"
            v-model="form.username"
            placeholder="请输入账号/邮箱"
          >
          </el-input>
        </el-form-item> -->
        <el-form-item
          class="logon_input"
          style="margin-top: 16px"
          prop="password"
        >
          <el-input
            class="logon_input"
            v-model="form_y.password"
            placeholder="请输入密码"
            autocomplete="new-password"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item
          class="logon_input"
          style="margin-top: 16px"
        >
          <div class="intensity"  style="text-align: left;">
            <div style="line-height: 20px;margin-top: 5px;">
              <span class="psdText">密码强度</span>
              <span
                class="line"
                :class="[level.includes('low') ? 'low' : '']"
              ></span>
              <span
                class="line"
                :class="[level.includes('middle') ? 'middle' : '']"
              ></span>
              <span
                class="line"
                :class="[level.includes('high') ? 'high' : '']"
              ></span>
            </div>

            <div class="warningtext" style="line-height: 20px">
              请填写正确的密码（8-16位数字、字母或符号的任意组合）
            </div>
          </div>
        </el-form-item>
        <el-form-item
          class="logon_input"
          style="margin-top: 16px"
          prop="password_two"
        >
          <el-input
            class="logon_input"
            v-model="form_y.password_two"
            placeholder="请再次输入密码"
            autocomplete="off"
            show-password
          >
          </el-input>
        </el-form-item>
      </el-form>

      <!-- 账号密码 -->

      <el-form
        :rules="rules"
        ref="ruleForm"
        v-if="dialogtype == 2"
        :model="form"
        style="text-align: center"
      >
        <!-- <el-form-item class="logon_input" prop="username">
          <el-input
            class="logon_input"
            v-model="form.username"
            placeholder="请输入账号/邮箱"
          >
          </el-input>
        </el-form-item> -->

        <el-form-item
          class="logon_input"
          style="margin-top: 16px"
          prop="password"
        >
          <el-input
            class="logon_input"
            v-model="form.password"
            placeholder="请输入密码"
            autocomplete="off"
            show-password
          >
          </el-input>
        </el-form-item>

        <el-form-item
          class="logon_input"
          style="margin-top: 16px"
          prop="password_two"
        >
          <el-input
            class="logon_input"
            v-model="form.password_two"
            placeholder="请输入密码"
            autocomplete="off"
            show-password
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div class="logon_dl_btn jvz_center" @click="logon_dl">
        <span v-if="dialogtype == 1">确认</span>
        <!-- <span v-if='dialogtype==2' >注册</span> -->
      </div>

      <div @click="$emit('zdy-text')" style="width: 380px;margin:0 auto;text-align: right;margin-top: 10px;cursor: pointer;">去登录</div>

      <div class="logon_dl_txt jvz_center_left_right">

        <!-- <div>
          <el-checkbox v-model="checked"></el-checkbox>
          <span>&nbsp;记住密码</span> |
          <router-link to="">忘记密码？</router-link>
        </div> -->
        <!-- <div>
          <router-link to="">注册账号？</router-link>
        </div> -->
      </div>

      <!-- <div class="logon_dl jvz_center_left_right">
        <div class="jvz_center logon_dl_sm">
          <div><img src="../assets/image/logo_ewm.png" alt="" /></div>
          <span>扫码登录</span>
        </div>
        <div class="jvz_center logon_dl_sm">
          <div><img src="../assets/image/logo_wx.png" alt="" /></div>
          <span>微信登录</span>
        </div>
      </div> -->
<!--      <div class="logon_dl_bottom jvz_center">
        <div>
          <el-checkbox
            style="margin-left: -16px"
            v-model="checked_yd"
          ></el-checkbox>
              <span  ></span>
                    我已阅读并接受 <span @click="urlxy">《注册声明》</span> <span @click="urlxyw">《隐私政策》</span>
            </span
          >
        </div>
      </div> -->

      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import md5 from "js-md5";
export default {
  data() {
    //手机号 自定义验证规则
    var checkAge = (rule, value, callback) => {
         if (!value) {
           callback(new Error("手机号不能为空"));
         } else if (!/^1(3|4|5|7|8)\d{9}$/.test(value)) {
           callback(new Error("请填写正确的手机号"));
         } else {
    //检验手机号是否被注册
         this.$axios
           .get("/api/blade-auth/oauth/check-phone?phone="+ this.form_y.uname_s,)
           .then((res) => {
             if (res.isExist==true) {
               callback();
             } else {
               callback(new Error("手机号码未注册！"));
             }
           })
         }
       };
       //验证码
       var checkyz = (rule, value, callback) => {
            if (!value) {
              callback(new Error("验证码不能为空"));
            }else {
            this.$axios
              .post(
                "api/blade-user/mobile/verification?" +
                  this.$qs.stringify({
                    phone: this.form_y.uname_s,
                    code: this.form_y.pasword_s,
                  })
              )
              .then((res) => {
                console.log(res)
                if (res.code==200) {
                  callback();
                } else {

                  callback(new Error("验证码不正确！"));
                }

              })
              .catch(function (err) {
                 callback(new Error("验证码不正确！"));
                 });
            }
          };
    // 密码验证
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form_y.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
      //密码验证1

    var validatePassmiyz= (rule, value, callback) => {
     this.level = []
           if (!value) {
             return callback('密码不能为空')
           }
           if (value.length < 8) {
             return callback('密码不少于8位')
           }
           if (value.length > 16) {
             return callback('密码不大于16位')
           }
           // 校验是数字
           const regex1 = /^\d+$/
           // 校验字母
           const regex2 = /^[A-Za-z]+$/
           // 校验符号
           const regex3 =
             /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/
           if (regex1.test(value)) {
             this.level.push('low')
           } else if (regex2.test(value)) {
             this.level.push('low')
           } else if (regex3.test(value)) {
             this.level.push('low')
           } else if (/^[A-Za-z\d]+$/.test(value)) {
             this.level.push('low')
             this.level.push('middle')
           } else if (
             /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]+$/.test(
               value
             )
           ) {
             this.level.push('low')
             this.level.push('middle')
           } else if (
             /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z]+$/.test(
               value
             )
           ) {
             this.level.push('low')
             this.level.push('middle')
           } else if (
             /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z\d]+$/.test(
               value
             )
           ) {
             this.level.push('low')
             this.level.push('middle')
             this.level.push('high')
           }
           return callback()
    };

    return {
      level:[],
      dialogtype: 1, //账号 手机号 登录 状态
      dialogFormVisible: true, //模态窗 隐藏
      checking_value: "获取验证码",
      disabled: false,
      //账号信息
      form: {},
      //手机号
      form_y: {
        uname_s: "",
        pasword_s: "",
        // username: "",
        password: "",
        password_two: "",
      },
      checked: false, //记住密码
      checked_yd: true, //阅读协议
      countdown:null,
      //手机号登录验证规则
      rules_s: {
        uname_s: [ { validator: checkAge, trigger: "blur" }],
        pasword_s: [
          { validator: checkyz, trigger: "blur" }
        ],
        password: [{ validator: validatePassmiyz,  trigger: "blur" }],
        password_two: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
            //跳转协议页面
    urlxy(){
 this.dialogFormVisible = false;

                       this.$router.push('/xy')



    },
           urlxyw(){
 this.dialogFormVisible = false;

      this.$router.push("/agreement");
    },
    //密码登录 手机登录 状态切换 验证效果取消
    logon_type(type_i) {
      if (this.dialogtype !== type_i) {
        this.dialogtype = type_i;

        if (this.dialogtype == 2) {
          this.$refs["ruleForm_s"].clearValidate();
        }
        if (this.dialogtype == 1) {
          this.$refs["ruleForm"].clearValidate();
        }
      }
    },

    //确认  //先验证表单
    logon_dl() {
      //账号登录验证
      if (this.dialogtype == 1) {
        this.$refs.ruleForm_s.validate((boolean, object) => {
          if (boolean) {
            console.log("通过");
            this.submit_zh();

            return;
            // this.submit_zh(); //验证通过
          } else {
            console.log("没通过");
            return false;
          }
        });
      }

      //账号信息验证
      if (this.dialogtype == 2 && this.$refs.ruleForm !== undefined) {
        console.log(this.form_y);

        this.$refs.ruleForm.validate((boolean, object) => {
          if (boolean) {
            //验证通过

            this.submit_zh();
          } else {
            console.log("没通过");
            return false;
          }
        });
      }
    },

    //忘记密码接口、
    submit_zh() {
      //   var password = //需要加密的md5密码

      var data = {
        newPassword: md5(this.form_y.password),
        code: this.form_y.pasword_s,
        phone: this.form_y.uname_s,
      };


      this.$axios
        .post("/api/blade-user/forget-password", this.$qs.stringify(data))
        .then((ret) => {
            console.log(ret)
          if (ret.code == 200) {
              this.$router.push('/home')
              sessionStorage.setItem("top_index", 0);
              location.reload()
              sessionStorage.clear()
              //修改成功  删除本地 token  刷新页面  重新登录

          }
        })
        .catch(function (err) {
          if (err.response.status !== 200) {
            alert(err.response.data.msg);
          }
        });
    },


    //获取验证码
    logon_yzm() {
      //先验证手机号
      this.$refs["ruleForm_s"].validateField(["uname_s"], (errorMsg) => {
        if (!errorMsg) {
             this.disabled = true; //禁用获取验证码
          clearInterval(this.countdown);
          this.doLoop(59);

          //验证通过  验证码倒计时

          this.$axios
            .get(
              "/api/blade-user/mobile/captcha" + "?phone=" + this.form_y.uname_s
            )
            .then((res) => {
              console.log(res);
            });
        }
      });
    },

    //手机验证码的倒计时
    doLoop: function (seconds) {
      this.disabled = true; //禁用获取验证码
      seconds = seconds ? seconds : 59;
      this.checking_value = seconds + "s后获取";
      // this.code = code
       this.countdown = setInterval(() => {
        if (seconds > 0) {
          this.checking_value = seconds + "s后获取";
          --seconds;
        } else {
          this.checking_value = "获取验证码";
          this.disabled = false; //开启
          clearInterval(this.countdown);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.logon_class {
  height: 540px;
  display: none;
}
#logon {
  .logon_logo {
    width: 164px;
    height: 51px;
    margin: 0 auto;
  }
  .logon_title {
    font-size: 16px;
    margin-top: 29px;
    cursor: pointer;

    span:nth-child(2) {
      margin-left: 40px;
    }
  }
  .logon_border {
    width: 216px;
    display: flex;
    background: #bfbfbf;
    height: 2px;

    margin: 15px auto 20px auto;
    div {
      width: 100%;
    }

    .logon_border_type {
      background: #ea551a;
    }
  }

  .logon_input {
    width: 380px;
    height: 40px;
    margin: 0 auto;
  }
  .logon_dl_btn {
    width: 380px;
    height: 50px;
    margin: 0 auto;
    margin-top: 20px;
    color: white;
    border-radius: 5px;
    background: #ea551a;
    cursor: pointer;
  }
  .logon_dl_txt {
    width: 380px;
    margin: 0 auto;
    margin: 11px auto 28px auto;
    div {
      a {
        color: #606266;
      }
    }
  }
  .logon_dl {
    width: 335px;
    margin: 0 auto;

    .logon_dl_sm {
      > div {
        width: 48px;
        height: 48px;
      }
      span {
        margin-left: 10px;
        font-size: 17px;
      }
    }
  }
  .logon_dl_bottom {
    width: 100%;
    height: 60px;
    background: #f5f5f5;
    margin-top: 28px;
    a {
      color: #606266;
    }
  }
}
.intensity {
  .psdText {
    font-size: 14px;
    margin-right: 10px;
  }
  .line {
    display: inline-block;
    width: 48px;
    height: 4px;
    background: #d8d8d8;
    border-radius: 3px;
    margin-right: 8px;
    &.low {
      background: #f4664a;
    }
    &.middle {
      background: #ffb700;
    }
    &.high {
      background: #2cbb79;
    }
  }
  .level {
    margin: 0 16px 0 8px;
  }
  .warningtext {
    color: #5a5a5a;
    font-size: 12px;
    margin-top: 5px;
  }
}
</style>
