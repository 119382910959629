<template>
  <div class="index">
    <BaseToTop></BaseToTop>
    <!-- 公用头 -->
    <Shop-Header @func="handle" ref='alltop'></Shop-Header>
    <!-- 内容 -->
     <keep-alive>
      <router-view ref="child1Container"    @funcgwc="gwchandle" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
      <router-view ref="child1Container"  @funcgwc="gwchandle" v-if="!$route.meta.keepAlive"></router-view>
    <!-- 公用底部 -->
    <Shop-Footer></Shop-Footer>
    <!-- 登录组件 -->
    <Lo-gon></Lo-gon>
  </div>
</template>

<script>

  import ShopHeader from '@/components/Alltop.vue'
  import ShopFooter from '@/components/Allfooter.vue'
  import Logon from '@/components/logon.vue'
  // import DevicePixelRatio from '@/util/zoom.js';//放大缩小适配
export default {

  name: 'index',
  data(){
    return{}
  },
  mounted(){
  },
  methods:{
    handle(tit){

    this.$refs.child1Container.funca(tit)

    },
    gwchandle(){
    this.$refs.alltop.gwcValue()
      //调用头部 购物车图标数量
    }
  },
  // created() {
  //   new DevicePixelRatio().init();//适配放大缩小
  // },
  components: {
   'Shop-Footer':ShopFooter,
   'Shop-Header':ShopHeader,
   'Lo-gon':Logon
  }
}
</script>
