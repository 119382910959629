<template>
  <div id="app">

    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>

export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  components: {
  },
  mounted(){
    console.log('app')
    // this.$axios
    //     .post(
    //       "/api/index/center/list/",
    //       this.$qs.stringify({
    //         pageSize: 7,
    //         pageNum: 1,
    //       })
    //     )
    //     .then((ret) => {
    //        console.log(ret.rows)
    //       this.can_tab_id = ret.rows;
    //     });
  },
  methods:{
    reload () {
  　　this.isRouterAlive = false
  　　this.$nextTick(function () {
    　　this.isRouterAlive = true
  　　})
　　}
  }
}
</script>

<style  lang='less'>
@import '@/assets/css/font.css'; /*引入公共样式*/
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;



}

.home{
   .el-carousel__container{
         position: relative;
         height: 100%;
   }
}

  .container {
      width: 1200px !important;
    /* min-width: 1200px; */
    margin: 0 auto;
  }



/* 登录组件样式 */
.el-icon-close:before {
    content: "\e6db";
    font-size: 20px;
}
.el-dialog__header{
  padding:0px !important;
}
.el-dialog__body{
      padding: 38px 0px 0px 0px !important;

      width:100%;
}
.el-checkbox__inner {
    border-color: #EA551A !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #EA551A !important;
    border-color: #EA551A !important;
}

//分页器 颜色
.el-pagination.is-background .el-pager li:not(.disabled).active{
      background-color: #e9541a !important;
    color: #FFF;
}

// 居中全局样式
.jvz_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jvz_center_left_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jvz_center_mean {
  display: flex;
  align-items: center;
  justify-content: space-around;
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
  margin: 0;
  padding: 0;
  border: 0;
   list-style: none;
 text-decoration: none;
  /* font-weight: normal; */
  vertical-align: baseline;
  box-sizing: border-box;
  /* font-size: 15px; */
}
/* img{
  width:100%;
  height: 100%;;
} */

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

body {
  /* line-height: 1; */
  background: #F4F5F7 !important;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.el-textarea__inner textarea{
  resize: none !important
}

 /* 颜色配置 */




</style>
