import axios from "axios";
import website from '@/request/website';
import {Base64} from 'js-base64';
import router from '@/router/index'
import NProgress from 'nprogress'
import { Message } from "element-ui";
import 'nprogress/nprogress.css'
import login from '@/components/logon.vue' // 引入弹窗组件
//请求超时时间
axios.defaults.timeout = 30 * 1000; //请求时间
// axios.default.baseURI = 'http://113.209.136.153:88';

  //请求发出前
axios.interceptors.request.use(

    function(config) {
      NProgress.start();
        //判断有没有token 有就在请求头携带
        if(sessionStorage.getItem('token')){
          config.headers[website.tokenHeader] = 'bearer ' + sessionStorage.getItem('token')
          // config.headers['userId'] = sessionStorage.getItem('user_id')

        }

  // hasOwnProperty('token')//判断 headers 里的token 是否有
       if (!config.headers.hasOwnProperty('token')) {

        config.headers['Tenant-Id'] = '000000';

        config.headers['Authorization'] = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`;
       }
      // sessionStorage.setItem('wojiao',val);
      // sessionStorage.getItem('wojiao')
      //请求发出之前，设置一些信息,比如设置请求头携带一个mytoen参数
      //  config.headers={
      //   ...config.header,
      //    'Content-Security-Policy':'Content-Security-Policy’: “script-src ‘self’; object-src ‘none’;style-src cdn.example.org third-party.org; child-src https:'
      //   }
      return config;
      //必要要return  config！！！
    },
    function(err) {
      console.log(err + "错误信息");
      //处理响应错误的信息
    }
  );

  /*axios响应拦截器  收到消息后
   * */
  axios.interceptors.response.use(
    function(res) {
      NProgress.done();

      console.log(res)

      if (res.status ==200) {
        var data = res.data;
        return data;
      }
      // else{
      //   Message({
      //     message: "服务器异常",
      //     type: "error",
      //   });
      //   return Promise.reject();
      // }
      //在获取数据之前，对数据进行加工处理，比如直接处理他的数据变成data
    },
    function(err) {
        console.log(err.response)
       //   Message({
       //     message: err.response.data.msg,
       //     type: "error",
       // });
         if(err.response.data.code==401){
           //请求 未授权  重新登录
           sessionStorage.removeItem('token');
           sessionStorage.removeItem('user_id');

          //  login.install()

           router.push('/home')
           sessionStorage.setItem("top_index", 0);

             location.reload();

         }
         // else{
         //  Message({
         //    message: "服务器异常",
         //    type: "error",
         //  });
         // }

      return Promise.reject(err);

      //处理响应错误的信息
    }
  );
  //分装的post
  // export function post(url, params) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(url, this.$qs.stringify(params))
  //       .then((res) => {
  //         resolve(res);
  //       })
  //       .catch((err) => {
  //         reject(res);
  //       });
  //   });
  // }
