import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import QS from 'qs'
import './plugins/element.js'
import './request/http'  //全局接口配置
import './request/api'  //全局 请求方法
import '@/global.js'

// import login from '@/components/compnelogoi.js' // 引入弹窗组件js
import { Loading ,MessageBox,Dialog} from 'element-ui';
Dialog.props.lockScroll.default = false;
Vue.use(Loading.directive)
Vue.prototype.$confirm = MessageBox.confirm


//播放器组件
import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer,{
  lang:'zh-CN'
})
 


Vue.prototype.$login = login['login'].install; // 全局可使用this.$login调用




import login from '@/components/compnelogoi.js' // 引入弹窗组件js

Vue.prototype.$login= login['login'].install; // 全局可使用this.$login调用


Vue.prototype.$loginzc = login['login'].installzc; // 全局可使用this.$login调用


// import '@/assets/js/rem' //rem
Vue.config.productionTip = false

//发请求使用 进行注册
Vue.prototype.$axios = axios;
Vue.prototype.$qs = QS //请求参数 格式转换AliyunUpload
Vue.prototype.$img_url = 'http://gm.tthse.com:88'  //图片路径


  //全局日期处理

Vue.filter('riqis',function (val) {
  console.log(val)
  if(val==undefined){
    return ''
  }else{
    var date = new Date(val);
    return formatDate(date, 'yyyy.MM.dd');

  }

 function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
     fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
     'M+': date.getMonth() + 1,
     'd+': date.getDate(),
     'h+': date.getHours(),
     'm+': date.getMinutes(),
     's+': date.getSeconds()
    };
    for (let k in o) {
     if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
     }
    }
    return fmt;
   };

   function padLeftZero(str) {
    return ('00' + str).substr(str.length);
   };



  });





 new Vue({
  router,
  store,
  render: h => h(App),

}).$mount('#app')
