<template>
  <div>
    <div style="width: 100%; height: 80px"></div>
    <div class="header">
      <header>
        <div class="container jvz_center_left_right">
          <div class="tit_left jvz_center">
            <div class="tit_logo" @click="pushHome">
              <img style="width: 170px;height: 50px;object-fit: fill;margin-top: 3px;" src="@/assets/image/logo.png" alt="" />
            </div>
            <ul class="jvz_center">
              <li v-for="(item, index) in top_list" :key="index">
                <span
                  :class="select_top == index ? 'add_class' : ''"
                  @click="router_url(index)"
                  >{{ item.uname }}</span
                >
              </li>

              <!-- <li class="hov_div">
              <span class="iconfont icon-24gf-ellipsis"></span>
              <ul class="dropdown_menu">
                <li>学员指南</li>
                <li>新闻资讯</li>
              </ul>
            </li> -->
            </ul>
          </div>
          <div class="tit_right jvz_center">
            <ul class="jvz_center_left_right jvz_rig">
              <li class="add_input">
                <div style="position: relative; width: 200px;display: flex;"  @mouseleave="show">
                  <input
                    style="font-size: 13px"
                    placeholder="搜索课程"
                    ref="getValue"
                    @focus='close'
                    v-model="lessonTitle2"
                    type="text"
                    @keyup.enter="kecheng"
                    @click="searchName"
                  />
                  <span class="inp_btn" @click="kecheng">
                    <i class="el-icon-search"></i
                  ></span>
                  <!--   -->
                  <div class="history" v-if="pastHistory"  >
                    <!-- <div style="font-size:13px;line-height: 30px;border-bottom: 1px solid #333;">历史搜索记录</div> -->
                    <!-- <ul> -->
                    <div v-for="(item, index) in historyList" :key="index">
                      <div class="search_line">
                        <span
                          class="search_name"
                          style="
                            display: inline-block;
                            cursor: pointer;
                            width: 150px;
                            font-size: 13px;
                            line-height: 25px;
                          "
                          @click.stop="clickHistory(item, index)"
                          >{{ item }}</span
                        >
                        <i class="el-icon-close" @click="deleteHis(index)"></i>
                      </div>
                    </div>
                    <!-- </ul> -->
                  </div>
                </div>
              </li>
              <!-- 消息 -->
              <!-- <li style="">
                <router-link to="/personalCenter?biaoji=1">
                  <el-badge :value="xiaoxi_value" class="item">
                    <span class="iconfont icon-xiaoxi1"></span>

                  </el-badge>
                </router-link>
              </li> -->

              <li>
                <router-link to="/ShoppingCart">
                  <!--购物车  -->
                  <el-badge
                    :value="gwc_value"
                    class="item"
                    v-if="gwc_value > 0"
                  >
                  <img
                    style="width:22px;height: 20px;"
                    src="../assets/image/cart.svg" alt="">
                    <!-- <span class="iconfont icon-gouwuche"></span> -->
                  </el-badge>
                  <img v-if="gwc_value == 0"
                    style="vertical-align: -webkit-baseline-middle;width:22px;height: 20px;"
                    src="../assets/image/cart.svg" alt="">
                <!--  <span
                    style=""

                    class="iconfont icon-gouwuche"
                  ></span> -->
                </router-link>
              </li>
              <!-- <li class="add_div">
              <span>学习</span>
            </li>
            <li class="add_div">
              <span>管理</span>
            </li> -->
              <!-- <li class="hov_div_right">
              <span style="font-size: 20px" class="iconfont icon-ren"></span>
              <ul class="dropdown_menu_r">
                <div>试看</div>
                <li>
                  <span
                    style="font-size: 20px"
                    class="iconfont icon-ren"
                  ></span>
                  个人主页
                </li>
                <li>
                  <span
                    style="font-size: 20px"
                    class="iconfont icon-ren"
                  ></span>
                  个人中心
                </li>
                <li>
                  <span style="font-size: 20px" class="iconfont icon-ren"></span
                  >通知
                </li>
                <li>
                  <span style="font-size: 20px" class="iconfont icon-ren"></span
                  ><span>私信</span>
                </li>
                <li>
                  <span style="font-size: 20px" class="iconfont icon-ren"></span
                  >登录
                </li>
              </ul>
            </li> -->
              <li v-if="logintype" style='width: 11 0px;justify-content: flex-end;' class="my_hedlog  hov_div_rightt">
                <router-link
                  to="personalCenter"
                  class="jvz_center_left_right"
                  style=" justify-content: end"
                >
                  <div style="width: 35px; margin-right: 5px;padding-top: 5px;"  @click="geren">
                    <img
                      style="width: 38px; height: 38px; border-radius: 50%"
                      v-if="mypersonaldata.avatar !== ''"
                      :src="mypersonaldata.avatar.includes('http')?mypersonaldata.avatar:'api'+mypersonaldata.avatar"
                      alt=""
                    />
                    <img
                      style="width: 38px; height: 38px; border-radius: 50%"
                      v-else
                      src="@/assets/image/h_r.png"
                      alt=""
                    />
                  </div>
                  <span   @click="geren"
                    style="padding: 0;line-height: 30px;" class="name_block" :title="loginname"
                    >{{ loginname }}
                  </span>
                </router-link>
                <ul class="dropdown_menu_rr" style="padding-left: 5px;">
                  <p class="hover"  @click="geren">
                    <img src="../assets/image/geren.svg" alt=""> 个人中心</p>
                  <p class="hover" @click="cleartcurl(1)">
                    <el-badge is-dot class="item" v-if="xiaoxi_value>0">
                     <img style="width:20px;height: 20px;"
                      src="../assets/image/xiaoxi.svg" alt="">
                    </el-badge>
                    <img  style="width:20px;height: 20px;"
                     v-else src="../assets/image/xiaoxi.svg" alt="">
                    我的消息</p>
                  <p class="hover" @click="cleartc()">
                    <img src="../assets/image/tuichu.svg" alt="">
                    退出登录</p>
                </ul>
              </li>

              <li
                v-if="!logintype"
                style="justify-content: flex-end;width:110px"
                class="dlzc"
              >
                <div>
                  <span style="padding: 0" @click="logon_dl('1')">登录</span
                  >&nbsp;|&nbsp;<span style="padding: 0" @click="logon_dl('2')"
                    >注册</span
                  >
                </div>
              </li>

              <!--  <li class="hov_div_rightt">
              <a href="Tthse://orderid=12">APP</a>
              <div class="dropdown_menu_rr2">
                <img src="../assets/image/qrcode.png" alt="" />
                <div style="text-align: center;">扫描二维码下载手机APP</div>
              </div>
            </li> -->
            </ul>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";

export default {
   inject: ["reload"],
  data() {
    return {
      mypersonaldata: {},
      lessonTitle2: "",
      xiaoxi_value: "",
      gwc_value: "",
      select_top: 0, //默认首页选中

      logintype: false, //登录的状态
      loginname: "", //用户名
      shurn: "",
      top_list: [
        {
          uname: "首页",
          url: "/home",
        },
        {
          uname: "课程超市",
          url: "/CourseCs",
        },
        {
          uname: "学习中心",
          url: "/personalCenter",
        },
        // {
        //   uname: "新闻公告",
        //   url: "/news",
        // },
        {
          uname: "关于我们",
          url: "/about",
        },
      ],
      productList: [],
      list: [],
      newArr: [],


      pastHistory: false,
      historyList: [],
    };
  },
  mounted() {
    var token = sessionStorage.getItem("token");
    if (token) {
      this.mypersonal();
      this.xiaoxiValue();
      this.gwcValue();
      this.logintype = true;
      // this.loginname= sessionStorage.getItem("user_name");
    } else {
      this.logintype = false;
    }
    console.log(this.loginname);
    var gindex = sessionStorage.getItem("top_index");
    console.log(gindex);
    if (gindex) this.select_top = gindex;
  },
  methods: {
    pushHome(){
      this.$router.push({ path: "/home",})
      this.select_top=0
    },
    showhide(){
        this.pastHistory = false;
    },
    mypersonal() {
      this.$axios.get("api/blade-user/info").then((res) => {
        this.mypersonaldata = res.data;
        console.log('sds')
        console.log(res.data)
        if(this.mypersonaldata.realName!==''){
          this.loginname=this.mypersonaldata.realName
        }else if(this.mypersonaldata.account!==''){
          this.loginname = this.mypersonaldata.account
        }else{
          this.loginname = this.mypersonaldata.phone
        }
        // this.loginname = this.mypersonaldata.realName!==''?this.mypersonaldata.realName:this.mypersonaldata.account;
      });
    },
    show() {
        this.pastHistory = false;

    },
    close() {
      if (this.historyList.length !== 0) {
        this.pastHistory = true; //有值显示历史记录
      } else {
        this.pastHistory = false;
      }
    },
    kecheng() {
      // console.log(htb)
      if (this.lessonTitle2 != "") {
        this.pastHistory = false; //每次点击搜索后历史记录就隐藏
        console.log(this.$refs.getValue.value);
        if (this.$refs.getValue.value !== "") {
          //判断输入框的值
          // 每次搜索的值push到新数组里
          this.newArr.push(this.$refs.getValue.value);
          console.log(this.newArr);
          this.newArr = this.unique(this.newArr); //调用unique方法去重
          this.list = [];



          for (let i = this.newArr.length; i > 0; i--) {
            //数组倒序  最新输入的排在最上面
            this.list.push(this.newArr[i - 1]);
          }
          if (this.list.length > 10) {
            //最多保存10条
            this.list = this.list.slice(0, 10);
          }

      console.log(this.list)
          localStorage.setItem("historyList", JSON.stringify(this.list)); //存localStorage
        }

        if (this.$route.path == "/CourseCs") {
          sessionStorage.setItem("lessonTitle2", this.lessonTitle2);
          this.$emit("func", this.lessonTitle2);
        } else {
          this.$router
            .push({
              path: "/CourseCs",
              query: {
                lessonTitle: this.lessonTitle2
              },
            })
            .then((res) => {
              this.select_top = 1;
              sessionStorage.setItem("top_index", this.select_top);

            });
        }
      } else {
        Message({
          message: "请输入内容",
          type: "error",
        });
      }
    },
    unique(arr) {
      return arr.filter(function (item, index, arr) {
        return arr.indexOf(item, 0) === index;
      });
    },
    // 点击文本框输入
    searchName() {

      if (localStorage.getItem("historyList")) {
        //historyList有值才执行接下来的操作
        let arrlist = JSON.parse(localStorage.getItem("historyList"));
        this.historyList = arrlist;
        if (this.historyList.length !== 0) {
          this.pastHistory = true; //有值显示历史记录
        }
      } else {
        this.pastHistory = false;
      }
    },
    // 点击历史记录直接搜索
    clickHistory(item, index) {

      this.pastHistory = false;
      this.lessonTitle2 = item;
      //接口前处理
      // this.filterData.productTitle = this.lessonTitle2;
      this.productList = [];
      // this.getProductList();   //调用搜索接口
      let listIndex = index;

      this.historyList.splice(0, 0, this.historyList[listIndex]); //每次点击记录被点击的展示在最前面
      this.historyList = this.unique(this.historyList); // 去重

      localStorage.setItem("historyList", JSON.stringify(this.historyList)); //新数组储存



    },
    //点击删除记录
    deleteHis(index) {

      if (isNaN(index) || index >= this.historyList.length) {
        return false;
      }
      this.historyList.splice(index, 1);
      console.log( this.historyList)
      this.newArr=this.historyList
      localStorage.setItem("historyList",   JSON.stringify(this.historyList)); //保存删除后的新数组


      if (this.historyList.length === 0) {
        this.pastHistory = false;
      }
    },
    //消息 未读获取
    xiaoxiValue() {
      this.$axios
        .get("api/blade-portal/portal/message/selectIsReadBySendUser")
        .then((res) => {
          console.log(res);
          this.xiaoxi_value = res.data.count;
        });
    },
    //购物车数量获取
    gwcValue() {
      this.$axios
        .get("api/blade-portal/portal/lesson-market/lessoncart/getCartCount")
        .then((res) => {
          console.log(res);
          this.gwc_value = res.data;
        });
    },
    //跳转我的消息
    cleartcurl(activeName){
      this.$router.push({name:'personalCenter',query:{biaoji:2}})
      sessionStorage.setItem("activeName", 2);
      sessionStorage.setItem("top_index",'');
      this.select_top = 2;

      // this.reload()
    },
    geren(){
      this.$router.push({name:'personalCenter',query:{biaoji:5}})
       sessionStorage.setItem("activeName", 5);
       sessionStorage.setItem("top_index", '');
      this.select_top = 2;

      //  this.reload()
    },
    //退出登录
    cleartc() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user_id");
      sessionStorage.removeItem("role_id");
      sessionStorage.setItem("top_index", 0);

      console.log("退出1");
      this.$router.push("/home").then((res) => {
        location.reload();
      });
    },
    //跳转导航
    router_url(index) {
      console.log(this.top_list[index].url)
      if(this.top_list[index].url=='/personalCenter'){
        console.log(this.top_list[index].url)
        
        if (sessionStorage.getItem("token")) {
          console.log(sessionStorage.getItem("token"))
          this.$router.push({ path: "/personalCenter",query:{biaoji:5} }).then((res) => {
            this.select_top = index;
            sessionStorage.setItem("top_index", this.select_top);
            sessionStorage.setItem("activeName", 5);
    
          });


        } else {
          this.$login({typeshow:'登录'});
        }
      }else{
        this.$router.push({ path: this.top_list[index].url }).then((res) => {
          this.select_top = index;
          sessionStorage.setItem("top_index", this.select_top);
          sessionStorage.setItem("activeName", 5);
        });
      }

      console.log(this.select_top)
    },
    //登录注册
    logon_dl(type) {
      if (type == "1") {
        this.$login({typeshow:'登录'});
      } else if (type == "2") {
        this.$loginzc({typeshow:'注册'});
      }
    },
  },
};
</script>
<style scoped lang="less">
header {
  background: white;
  position: fixed;
  top: 0;
  // left: 50%;
  //   transform: translate(-50%);
  //   width: 1366px!important
  z-index: 999;
  width: 100%;
}
.jvz_center > li:nth-child(1) {
  margin-left: 30px;
}
.history {
  position: absolute;
  width: 200px;
  background: #fff;
  top: 37px;

  z-index: 999;
  border: 1px solid #ea551a;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  .search_name {
    color: #333 !important;
  }
  .search_line:hover {
    background: #f0f0f0 !important;
  }
}
.search_line {
display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px 0 5px;
  // border-bottom: 1px solid #333;
}
.name_block {
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container {
  margin-block-start: auto;
  height: 80px;
  width: 1200px;
  // max-width: 80%;
  background: white;
  // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.01), 0 4px 4px 0 rgba(0, 0, 0, 0.04);

  .tit_left,
  .tit_right {
    height: 100%;

    ul > li > span {
      width: 100px;
      display: inline-block;
      text-align: center;
      line-height: 80px;
      cursor: pointer;
      color: black;
    }
    // ul li span:hover {
    //   color: white;
    //   background: #e9541a;
    // }
    .dlzc {
      span {
        color: black !important;
        cursor: pointer;
      }
    }
    // .jvz_rig li span:hover {
    //   background: none !important;
    //   color: black;
    // }
    .jvz_rig li {
      // flex: 1;
      display: flex;
      align-items: center;
      height: 100%;
      text-align: center;
      // justify-content: center;
      span {
        // padding-left: 30px;
        color: #e9541a;
        text-align: left;
      }
    }
    .add_class {
      color: white;
      font-weight: 600;
      background: #e9541a;
    }
    ul {
      width: 100%;
      // display: flex;
      // justify-content: start;
    }
    .jvz_rig {
      justify-content: space-between;
      height: 100%;
    }
    .hov_div {
      position: relative;
      &:hover .dropdown_menu {
        display: block;
      }
    }
    .hov_div_right {
      position: relative;
      &:hover .dropdown_menu_r {
        display: block;
      }
      .dropdown_menu_r {
        span {
          padding: 0px 10px 3px 0px;
        }
        li {
          display: flex;
          align-items: center;
        }
        div {
          text-align: center;
          line-height: 30px;
          border-bottom: 1px solid #f5f5f5;
        }
      }
    }

    .hov_div_rightt {
      position: relative;
      cursor: pointer;
      &:hover .dropdown_menu_rr {
        display: block;
        margin-top: 30px;
      }
      &:hover .dropdown_menu_rr2 {
        display: block;
        margin-top: 30px;
      }
      .tit_rr_img {
        width: 140px;
        margin: 0 auto;
      }
      p {
        line-height: 25px;
        width: 130px;
        margin: 0 auto;
        cursor: pointer;
      }
    }

    .dropdown_menu,
    .dropdown_menu_r,
    .dropdown_menu_rr {
      position: absolute;
      top: 35px;
      left: -15px;
      z-index: 1000;
      // float: left;
      min-width: 120px;
      padding: 5px 0;
      padding-left: 0 !important;
      margin: 0;
      display: none;
      list-style: none;
      font-size: 14px;
      // text-align: left;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
      li {
        padding: 10px 20px;
        color: black;
        cursor: pointer;
      }
      // li:hover {
      //   color: white;
      //   background: #e9541a;
      // }
    }
    .dropdown_menu_rr2 {
      position: fixed;
      top: 35px;
      right: 0px;
      z-index: 1000;
      min-width: 160px;
      padding: 5px 0;
      margin: 0;
      display: none;
      list-style: none;
      font-size: 14px;
      text-align: left;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
      li {
        padding: 10px 20px;
        color: black;
        cursor: pointer;
      }
      // li:hover {
      //   color: white;
      //   background: #e9541a;
      // }
    }
    .add_div {
      position: relative;
    }
    .add_div:before {
      content: "";
      position: absolute;
      left: 0;
      width: 1px;
      height: 20px;
      background-color: #e1e1e1;
    }
    input {
      width: 190px;
      color: #333;
      padding-left: 5px;
      outline: none;
      line-height: 35px;
      border: 1px solid #e9541a !important;
      vertical-align: middle;
    }
    input::-webkit-input-placeholder {
      color: #ccc;
    }

    .inp_btn {
      display: inline-block;
      padding: 0 10px !important;
      color: #fff !important;
      text-align: center !important;
      background: #e9541a !important;
      border: 1px solid #e9541a !important;
      line-height: 35px;
      vertical-align: middle;
      cursor: pointer;
    }
    // .inp_btn:hover {
    //   color: #fff!important;
    //   background: #e9541a !important;
    // }
    .add_input {
      span {
        position: relative;
        z-index: 2;
      }
    }

    .add_input:hover input {
      // width: 97px;

      // transition: 0.6s;
      // background: none;

      // border-bottom: 1px solid #666;
      // // transform: translateX(-80px);
      // z-index: 1;
    }
  }
  .tit_left {
    width: 57%;
  }
  .tit_right {
    width: 34%;
  }
  .tit_logo {
    width: 182px;
    padding-left: 20px;
    cursor: pointer;
  }
  .my_hedlog {
        // overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      color: black !important;
      font-weight: 800;
      white-space: nowrap;
    }
    img {
      border: solid 1px white;
    }
    > div:nth-child(1) {
      border: solid 1px #e9541a;
      border-radius: 100%;
      overflow: hidden;
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }
  }
}
.iconfont {
  font-size: 20px !important;
}
.hover{
  width: 120px !important;
  text-align: center;
  line-height: 40px !important;
  color: #666 !important;
  >img{
    width:20px;
    height: 20px;
    vertical-align: middle;
  }
}
.hover:hover{
  font-weight: bold;
}
.item {
  margin-top: 10px
}
/deep/.el-badge__content.is-fixed.is-dot {
    right:10px !important;
    top: 7px !important;
}
/deep/.el-badge__content.is-dot {
    height: 10px !important;
    width: 10px !important;
}
</style>
