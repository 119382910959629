
<template>
  <div>
    <div class="list" v-if="list">
      <div @mouseenter="showEwm" @mouseleave="closeEwm">
        <div style="position: relative;">
          <img class="none"  style="width: 27px;height: 28px;" src="../assets/image/to-top-logo.png" alt="">
          <img  class="block" style="width: 27px;height: 28px;" src="../assets/image/to-top-logo2.png" alt="">
        </div>
      </div>
      <div @mouseenter="gongzhonghao" @mouseleave="closeGzh">
        <div style="position: relative;">
          <img class="none"  src="../assets/image/wx2.png" alt="">
          <img  class="block" style="width: 30px;height:30px;" src="../assets/image/wx.png" alt="">
        </div>
      </div>
      <div @mouseenter="douyin" @mouseleave="closeDy">
        <div style="position: relative;">
          <img class="none" src="../assets/image/douyin2.png" alt="">
          <img class="block" style="width: 30px;height: 30px;" src="../assets/image/douyin.png" alt="">
        </div>
      </div>
      <div @mouseenter="showLiuyan">
        <div style="position: relative;">
          <img class="none" src="../assets/image/liuyan2.png" alt="">
          <img class="block" style="width: 30px;height: 30px;" src="../assets/image/liuyan.png" alt="">
        </div>
      </div>
   <!--   <div @click="hide">
        <el-tooltip style="z-index:99999" content="收起" placement="bottom" effect="light">
          <img style="width: 32px;height: 32x;" src="../assets/image/shouqi.png" alt="">
        </el-tooltip>
      </div> -->
      <div @click="toTop">
        <div style="position: relative;">
          <img style="width: 24px;height: 19px;" src="../assets/image/totop.png" alt="">
        </div>
      </div>
    </div>
    <div class="hide_btn" v-if="hideBtn"  @click="hide">
      <img style="width: 33px;height: 48px;" src="../assets/image/hide.png" alt="">
    </div>
    <div class="show_btn" v-if="btn" @click="show">
      <img style="width: 33px;height: 48px;" src="../assets/image/show.png" alt="">
    </div>
    <!-- 二维码 -->
    <div class="ewm" v-if="ewm">
      <img src="../assets/image/ewm.png" alt="" />
      <div style="text-align: center;">扫码下载APP</div>
    </div>
    <div class="ewm gzh" v-if="gzh">
      <img src="../assets/image/gzh.jpg" alt="" />
      <div style="text-align: center;">微信公众号</div>
    </div>
    <div class="ewm dy" v-if="dy">
      <img src="../assets/image/dy.jpg" alt="" />
      <div style="text-align: center;">抖音</div>
    </div>
    <!-- 留言板 -->
    <div class="liuyan" v-if="liuyan">
      <div class="ly_title">
        <div>留言板</div>
        <div  @click="closeLiuyan">
          <img style="width:14px;height: 14px;" src="../assets/image/close-ly.png" alt="">
        </div>
      </div>
      <div style="padding:0 20px">
        <div style="line-height:40px">高名安全 010-64813941</div>
        <!-- <div style="line-height:20px"></div> -->
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
          <el-form-item prop="contacts">
            <el-input v-model="ruleForm.contacts" placeholder="联系人"></el-input>
          </el-form-item>
          <el-form-item prop="phoneNumber">
            <el-input v-model="ruleForm.phoneNumber" placeholder="手机号(必填)"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.email" placeholder="邮箱"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.address" placeholder="地址"></el-input>
          </el-form-item>
          <el-form-item prop="messageContent">
            <el-input type="textarea" v-model="ruleForm.messageContent"
              placeholder="请在此输入留言内容，我们会尽快与您联系(必填）"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="btn" @click="submitForm('ruleForm')">提交</div>
           <!--   <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button> -->
            </el-form-item>
          <!-- <div class="btn" @click="submit">提交</div> -->
        </el-form>
      </div>

    </div>
  </div>

</template>

<script>
  import { Message } from "element-ui";
export default {
  name: 'ToTop',
  data(){
    var checkAge = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
      } else if (!/^1(3|4|5|7|8)\d{9}$/.test(value)) {
        callback(new Error("请填写正确的手机号"));
      } else {
        callback();
      }
    };
    return{
      hideBtn:true,
      btn:false,
      list:true,
      dy:false,
      gzh:false,
      ewm:false,
      liuyan:false,
      ruleForm: {
        contacts: '',
        phoneNumber: '',
        email: '',
        address: '',
        messageContent: '',
      },
      rules: {
        phoneNumber: [
          { validator: checkAge, trigger: "blur" }
        ],
        contacts: [
          { required: true, message: '联系人不能为空', trigger: 'blur' }
        ],
        messageContent: [
          { required: true, message: '留言内容不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    //隐藏
    hide(){
      this.hideBtn=false
      this.list=false
      this.btn=true
      this.liuyan=false
    },
    show(){
      this.hideBtn=true
      this.list=true
      this.btn=false
    },
    submitForm(formName){
      let MessageBoardVO=this.ruleForm
       this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios
              .post("api/blade-portal/portal/message/addMessageBoardInfo?", this.$qs.parse(MessageBoardVO))
              .then((res) => {
                console.log(res);
                Message({
                  message: "操作成功！",
                  type: "success",
                });
                this.liuyan=false
                this.$refs[formName].resetFields();
              });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    douyin(){
      this.dy=true
    },
    closeDy(){
      this.dy=false
    },
    gongzhonghao(){
      this.gzh=true
    },
    closeGzh(){
      this.gzh=false
    },
    showEwm(){
      this.ewm=true
    },
    closeEwm(){
      this.ewm=false
    },
    showLiuyan(){
      this.liuyan=true
    },
    closeLiuyan(){
      this.liuyan=false
    },

  }
}
</script>
<style scoped lang="less">
  .show_btn{
    position: fixed;
    top:235px;
    right: 0;
    cursor: pointer;
    z-index:9999
  }
.hide_btn{
  position: fixed;
  top:235px;
  right: 55px;
  cursor: pointer;
  z-index: 999;
}
.list{
  width:55px;
  height: 100%;
  position: fixed;
  top:0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  font-size: 16px;
  padding-top: 100px;
  box-shadow: -4px 0 15px -3px #E2E3E5;
}
.list>div{
  width:55px;
  text-align: center;
  height: 80px;
  border-bottom: 1px solid #E7E7E7 ;
  padding-top: 20px;
}
.list>div:hover{
  background-color: #E9541A;
  color: #fff;
}
.none{
  width:30px;
  height: 30px;
  display: none;
  margin:0 auto;
}
.list>div:hover .none{
  display: block;
}
.list>div:hover .block{
  display: none;
}
// .list>div:nth-last-child(1){
//   border:0;
//   position: fixed;
//   bottom: 70px;
//   right: 0;
//   cursor: pointer;
//   height:40px !important
// }
// .list>div:nth-last-child(1):hover{
//   background-color: rgba(0,0,0,0) !important;
// }
.list>div:nth-last-child(1):hover{
  background-color: #fff !important;
}
.list>div:nth-last-child(1){
  border:0;
  position: fixed;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.list>div:nth-last-child(1){
  border:0
}
.text{
  font-size: 16px;
  line-height: 35px;
}

.ewm{
  width:171px;
  height:202px;
  background-color: #E9541A;
  position: fixed;
  top:80px;
  right: 55px;
  z-index: 9999;
  text-align: center;
  padding-top: 10px;
  color: #fff;
}
.ewm>img{
  width:153px;
  height: 153px;
  margin-bottom: 5px;
}
.gzh{
  position: fixed;
  top:160px !important
}
.dy{
  position: fixed;
  top:240px !important;
  z-index: 9999;
}
.liuyan{
  width: 320px;
  position: fixed;
  bottom:2%;
  right: 55px;
  z-index: 999;
  background-color: #fff;
}
/deep/.el-form-item {
    margin-bottom: 16px !important;
}
.ly_title{
  display: flex;
  width:100%;
  line-height: 35px;
  background-color: #E9541A;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding:0 20px
}
/deep/.el-input__inner{
    height: 30px !important;
    line-height: 30px !important;
    color: #999 !important;
}
/deep/ .el-form-item__content{
  line-height: 30px !important;
}
.btn{
  width: 70px !important;
  height: 30px !important;
  line-height: 30px !important;
  background-color: #E9541A;
  color: #fff !important;
  border-radius: 5px;
  text-align: center;
  margin-left: auto;
  margin-bottom: 5px;
  cursor: pointer;
}
/deep/.el-textarea__inner {
    resize: none !important;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
  }

</style>
