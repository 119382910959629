<template>
  <div id="logon">

    <el-dialog
		:close-on-click-modal="false"
      width="600px"
      custom-class="logon_class jvz_center"
      top="15vh"
      :visible.sync="dialogFormVisible"
    >
      <div class="logon_logo">
        <img
          src="../assets/image/logo_dl.png"
          alt=""
        />
      </div>

      <div class="logon_title jvz_center">
        <!-- <span @click="logon_type(1)">验证手机号</span>
        <span @click="logon_type(2)">填写账号信息</span> -->
        <span>验证手机号</span>
        <span>填写账号信息</span>
      </div>
      <div class="logon_border">
        <div :class="dialogtype == 1 ? 'logon_border_type' : ''"></div>
        <div :class="dialogtype == 2 ? 'logon_border_type' : ''"></div>
      </div>

      <!-- 手机号验证 -->

      <el-form
        :rules="rules_s"
        ref="ruleForm_s"
        v-show="dialogtype == 1"
        :model="form_y"
        style="text-align: center"
      >
        <el-form-item
          class="logon_input"
          prop="uname_s"
        >
          <el-input
            v-model="form_y.uname_s"
            placeholder="请输入手机号"
          >
          </el-input>
        </el-form-item>
        <div style="position: relative">
          <el-form-item
            class="logon_input"
            style="margin-top: 20px"
            prop="pasword_s"
          >
            <el-input
              v-model="form_y.pasword_s"
              placeholder="请输入验证码"
            >
            </el-input>
            <el-button
              style="
                position: absolute;
                transform: translate(-81px, 0px);
                color: #ea551a;
              "
              :disabled="disabled"
              @click="logon_yzm"
              type="text"
            >{{ checking_value }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>

      <!-- 账号密码 -->

      <el-form
        :rules="rules"
        ref="ruleForm"
        v-show="dialogtype == 2"
        :model="form"
        style="text-align: center"
      >
        <el-form-item
          class="logon_input"
          prop="username"
        >
          <el-input
            class="logon_input"
            v-model="form.username"
            placeholder="请输入账号"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          class="logon_input"
          style="margin-top: 20px"
          prop="password"
        >
          <el-input
            class="logon_input"
            v-model="form.password"
            placeholder="请输入密码"
            autocomplete="new-password"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item
          class="logon_input"
          style="margin-top: 16px"
        >
          <div class="intensity"  style="text-align: left;">
            <div style="line-height: 20px;margin-top: 5px;">
              <span class="psdText">密码强度</span>
              <span
                class="line"
                :class="[level.includes('low') ? 'low' : '']"
              ></span>
              <span
                class="line"
                :class="[level.includes('middle') ? 'middle' : '']"
              ></span>
              <span
                class="line"
                :class="[level.includes('high') ? 'high' : '']"
              ></span>
            </div>

            <div class="warningtext" style="line-height: 20px">
              请填写正确的密码（8-16位数字、字母或符号的任意组合）
            </div>
          </div>
        </el-form-item>
        <el-form-item
          class="logon_input"
          style="margin-top: 20px"
          prop="password_two"
        >
          <el-input
            class="logon_input"
            v-model="form.password_two"
            placeholder="请输入密码"
            autocomplete="off"
            show-password
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div
        class="logon_dl_btn jvz_center"
        @click="logon_dl"
      >
        <span v-if="dialogtype == 1">下一步</span>
        <span v-if="dialogtype == 2">注册</span>
      </div>
      <div
        @click="$emit('zdy-text')"
        style="width: 380px;margin:0 auto;text-align: right;margin-top: 10px;cursor: pointer;"
      >去登录</div>
      <div class="logon_dl_txt jvz_center_left_right">
        <!-- <div>
          <el-checkbox v-model="checked"></el-checkbox>
          <span>&nbsp;记住密码</span> |
          <router-link to="">忘记密码？</router-link>
        </div> -->
        <!-- <div>
          <router-link to="">注册账号？</router-link>
        </div> -->
      </div>

      <!-- <div class="logon_dl jvz_center_left_right">
        <div class="jvz_center logon_dl_sm">
          <div><img src="../assets/image/logo_ewm.png" alt="" /></div>
          <span>扫码登录</span>
        </div>
        <div class="jvz_center logon_dl_sm">
          <div><img src="../assets/image/logo_wx.png" alt="" /></div>
          <span>微信登录</span>
        </div>
      </div> -->
      <div class="logon_dl_bottom jvz_center">
        <div>
          <el-checkbox
            style="margin-left: -16px"
            v-model="checked_yd"
          ></el-checkbox>
          <span>
            我已详细阅读并同意 <span style="cursor: pointer;color: #E9541A;" @click="urlxy">《用户协议》</span><span style="color: #333;cursor: text;">与</span> <span style="cursor: pointer;color: #E9541A;" @click="urlys">《隐私政策》</span>

          </span>
        </div>
      </div>

      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div> -->
    </el-dialog>
    <el-dialog
      :title="xyTitle"
      :visible.sync="dialogXy"
      width="600px"
      top="15vh"
      :before-close="handleClose">
      <div v-html="xy">
        <!-- <div>{{xy}}</div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import md5 from "js-md5";
export default {
  data() {
    //手机号 自定义验证规则
    var checkAge = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
      } else if (!/^1(3|4|5|7|8|9)\d{9}$/.test(value)) {
        callback(new Error("请填写正确的手机号"));
      } else {
        //检验手机号是否被注册
        this.$axios
          .get("/api/blade-auth/oauth/check-phone?phone=" + this.form_y.uname_s)
          .then((res) => {
            if (res.isExist == false) {
              callback();
            } else {
              callback(new Error("手机号码已注册！"));
            }
          });
      }
    };
    //验证码
    var checkyz = (rule, value, callback) => {
      if (!value) {
        callback(new Error("验证码不能为空"));
      } else {
        this.$axios
          .post(
            "api/blade-user/mobile/verification?" +
              this.$qs.stringify({
                phone: this.form_y.uname_s,
                code: this.form_y.pasword_s,
              })
          )
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              callback();
            } else {
              callback(new Error("验证码不正确！"));
            }
          })
          .catch(function (err) {
            callback(new Error("验证码不正确！"));
          });
      }
    };
    // 密码验证
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    //密码验证
    // // 密码验证
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请再次输入密码"));
    //   } else if (value !== this.form_y.password) {
    //     callback(new Error("两次输入密码不一致!"));
    //   } else {
    //     callback();
    //   }
    // };
      //密码验证1

    var validatePassmiyz= (rule, value, callback) => {
     //  if (!value) {
     //    callback(new Error("请输入密码"));
     //  } else if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9 _]{8,16}$/.test(value)) {
     //    callback(new Error("请填写正确的密码（8-16位数字或字母）"));
     //  } else {

     // callback();
     //  }
     this.level = []
           if (!value) {
             return callback('密码不能为空')
           }
           if (value.length < 8) {
             return callback('密码不少于8位')
           }
           if (value.length > 16) {
             return callback('密码不大于16位')
           }
           // 校验是数字
           const regex1 = /^\d+$/
           // 校验字母
           const regex2 = /^[A-Za-z]+$/
           // 校验符号
           const regex3 =
             /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/
           if (regex1.test(value)) {
             this.level.push('low')
           } else if (regex2.test(value)) {
             this.level.push('low')
           } else if (regex3.test(value)) {
             this.level.push('low')
           } else if (/^[A-Za-z\d]+$/.test(value)) {
             this.level.push('low')
             this.level.push('middle')
           } else if (
             /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]+$/.test(
               value
             )
           ) {
             this.level.push('low')
             this.level.push('middle')
           } else if (
             /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z]+$/.test(
               value
             )
           ) {
             this.level.push('low')
             this.level.push('middle')
           } else if (
             /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z\d]+$/.test(
               value
             )
           ) {
             this.level.push('low')
             this.level.push('middle')
             this.level.push('high')
           }
           return callback()
    };
    // var validatePassmiyz = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("请输入密码"));
    //   } else if (
    //     !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9 _]{8,16}$/.test(value)
    //   ) {
    //     callback(
    //       new Error("请填写正确的密码(8到16位（包含大写字母，小写字母，数字）)")
    //     );
    //   } else {
    //     callback();
    //   }
    // };

    //账号验证
    var validatePasszz = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入账号"));
      } else if (!/^[A-Za-z0-9 _]{4,20}$/.test(value)) {
        callback(
          new Error("请填写正确的账号(4-20位字母或数字的任意组合)")
        );
      } else {
        //检验手机号是否被注册
        this.$axios
        .get(
          "/api/blade-user/deWeight?" +
            this.$qs.stringify({
              account: this.form.username,
              id: null,
            })
        ).then((res) => {
            console.log(res)
            if (res.data == true) {
              callback();
            } else {
              callback(new Error("该账号已注册！"));
            }
          });
      }
    };

    return {
      level:[],
      dialogXy:false,
      xyTitle:"",
      xy:"",
      countdown:null,
      dialogtype: 1, //账号 手机号 登录 状态
      dialogFormVisible: true, //模态窗 隐藏
      checking_value: "获取验证码",
      disabled: false,
      //账号信息
      form: {
        username: "",
        password: "",
        password_two: "",
      },
      //手机号
      form_y: {
        uname_s: "",
        pasword_s: "",
      },
      checked: false, //记住密码
      checked_yd: false, //阅读协议

      //账号密码登录验证规则
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { validator: validatePasszz, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePassmiyz, trigger: "blur" },
        ],
        password_two: [{ validator: validatePass2, trigger: "blur" }],
      },
      //手机号登录验证规则
      rules_s: {
        uname_s: [{ validator: checkAge, trigger: "blur" }],
        pasword_s: [{ validator: checkyz, trigger: "blur" }],
      },
    };
  },
  methods: {
    handleClose(){
      this.dialogXy=false
    },
    //跳转协议页面
    urlxy() {
      // this.dialogFormVisible = false;
      // this.$router.push("/xy");
      this.$axios
        .get("api/blade-portal/portal/desk/getUserByAgreement")
        .then((res) => {
          console.log(res);
          for(let i=0;i<res.data.length;i++){
            if(res.data[i].type=="用户协议"){
              this.xyTitle="用户协议"
              this.xy=res.data[i].test
            }
          }

          this.dialogXy=true
        }).catch(err=>{
            console.log(err.response)

        });
    },
    urlys() {
      // this.dialogFormVisible = false;
      // this.$router.push("/xy");
      this.$axios
        .get("api/blade-portal/portal/desk/getUserByAgreement")
        .then((res) => {
          console.log(res);
          for(let i=0;i<res.data.length;i++){
            if(res.data[i].type=="隐私政策"){
              this.xyTitle="隐私政策"
              this.xy=res.data[i].test
            }
          }

          this.dialogXy=true
        }).catch(err=>{
            console.log(err.response)

        });
    },
    //密码登录 手机登录 状态切换 验证效果取消
    logon_type(type_i) {
      if (this.dialogtype !== type_i) {
        this.dialogtype = type_i;
        if (this.dialogtype == 2) {
          this.$refs["ruleForm_s"].clearValidate();
        }
        if (this.dialogtype == 1) {
          this.$refs["ruleForm"].clearValidate();
        }
      }
    },
    //下一步  //先验证表单
    logon_dl() {
      if (this.checked_yd == true) {
        //账号登录验证
        if (this.dialogtype == 1) {
          this.$refs.ruleForm_s.validate((boolean, object) => {
            if (boolean) {
              console.log("通过");
              this.dialogtype = 2;
              return;
              // this.submit_zh(); //验证通过
            } else {
              console.log("没通过");
              return false;
            }
          });
        }
        //账号信息验证
        if (this.dialogtype == 2 && this.$refs.ruleForm !== undefined) {
          this.$refs.ruleForm_s.validate((boolean, object) => {
            if (boolean) {
              console.log("通过");
              this.$refs.ruleForm.validate((boolean, object) => {
                if (boolean) {
                  //验证通过

                  this.submit_zh();
                } else {
                  console.log("没通过");
                  return false;
                }
              });
              return;
              // this.submit_zh(); //验证通过
            } else {
              console.log("没通过");
              this.dialogtype = 1;

              return false;
            }
          });
        }
      } else {
        Message({
          message: "请先勾选用户协议！",
          type: "error",
        });
      }
    },

    //注册
    submit_zh() {
      var password = md5(this.form.password); //需要加密的md5密码
      var data = {
        tenantId: "000000",
        account: this.form.username,
        code: this.form_y.pasword_s,
        password: this.form.password,
        phone: this.form_y.uname_s,
      };
      this.$axios
        .post("/api/blade-user/mobile/register", this.$qs.stringify(data))
        .then((ret) => {
          console.log(ret);
          if (ret.code == 200) {
            // return;
            this.dialogFormVisible = false;
            //注册成功
            var password = md5(this.form.password); //需要加密的md5密码
            this.$axios
              .post(
                "/api/blade-auth/oauth/token",
                this.$qs.stringify({
                  username: this.form.username, //
                  tenantId: "000000",
                  password: password,
                  grant_type: "password",
                  scope: "all",
                })
              )
              .then((ret) => {
                console.log(ret);
                    sessionStorage.setItem("role_id", ret.role_id); //把token 存本地

                sessionStorage.setItem("token", ret.access_token); //把token 存本地
                sessionStorage.setItem("user_name", ret.user_name); //把token 存本地
                sessionStorage.setItem("user_id", ret.user_id); //把token 存本地
                if (ret.access_token) {
                  console.log(location);
                  console.log(this);
                  location.pathname = "/home";
                  sessionStorage.setItem("top_index", 0);
                }
              })
              .catch(function (err) {
                //  location.reload();
                console.log(err.response);
                // if (err.response.status !== 200) {
                Message({
                  message: err.response.error_description,
                  type: "error",
                });
                // }
              });
          }
        }).catch(err=>{
            console.log(err.response)

        });
    },

    //获取验证码
    logon_yzm() {
      //先验证手机号
      this.$refs["ruleForm_s"].validateField(["uname_s"], (errorMsg) => {
        if (!errorMsg) {
          //验证通过  验证码倒计时
         this.disabled = true; //禁用获取验证码
          clearInterval(this.countdown);
          this.doLoop(59);
          this.$axios
            .get(
              "/api/blade-user/mobile/captcha" + "?phone=" + this.form_y.uname_s
            )
            .then((res) => {
              console.log(res);
            })
            .catch(function (err) {
              //  location.reload();

              console.log(err);
              // if (err.response.status !== 200) {
              // Message({
              //   message: err.response.error_description,
              //   type: "error"
              // });
              // }
            });
        }
      });
    },

    //手机验证码的倒计时
    doLoop: function (seconds) {
      this.disabled = true; //禁用获取验证码
      seconds = seconds ? seconds : 59;
      this.checking_value = seconds + "s后获取";
      // this.code = code
      this.countdown = setInterval(() => {
        if (seconds > 0) {
          this.checking_value = seconds + "s后获取";
          --seconds;
        } else {
          this.checking_value = "获取验证码";
          this.disabled = false; //开启
          clearInterval(this.countdown);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
  /deep/.el-dialog{
    padding:20px !important
  }
.logon_class {
  height: 540px;
  display: none;
}
#logon {
  .logon_logo {
    width: 164px;
    height: 51px;
    margin: 0 auto;
  }
  .logon_title {
    font-size: 16px;
    margin-top: 29px;
    cursor: pointer;

    span:nth-child(2) {
      margin-left: 40px;
    }
  }
  .logon_border {
    width: 216px;
    display: flex;
    background: #bfbfbf;
    height: 2px;

    margin: 15px auto 20px auto;
    div {
      width: 50%;
    }

    .logon_border_type {
      background: #ea551a;
    }
  }

  .logon_input {
    width: 380px;
    height: 40px;
    margin: 0 auto;
  }
  .logon_dl_btn {
    width: 380px;
    height: 50px;
    margin: 0 auto;
    margin-top: 20px;
    color: white;
    border-radius: 5px;
    background: #ea551a;
    cursor: pointer;
  }
  .logon_dl_txt {
    width: 380px;
    margin: 0 auto;
    margin: 11px auto 28px auto;
    div {
      a {
        color: #606266;
      }
    }
  }
  .logon_dl {
    width: 335px;
    margin: 0 auto;

    .logon_dl_sm {
      > div {
        width: 48px;
        height: 48px;
      }
      span {
        margin-left: 10px;
        font-size: 17px;
      }
    }
  }
  .logon_dl_bottom {
    width: 100%;
    height: 60px;
    background: #f5f5f5;
    margin-top: 28px;
    a {
      color: #606266;
    }
  }
}
.intensity {
  .psdText {
    font-size: 14px;
    margin-right: 10px;
  }
  .line {
    display: inline-block;
    width: 48px;
    height: 4px;
    background: #d8d8d8;
    border-radius: 3px;
    margin-right: 8px;
    &.low {
      background: #f4664a;
    }
    &.middle {
      background: #ffb700;
    }
    &.high {
      background: #2cbb79;
    }
  }
  .level {
    margin: 0 16px 0 8px;
  }
  .warningtext {
    color: #5a5a5a;
    font-size: 12px;
    margin-top: 5px;
  }
}
</style>
