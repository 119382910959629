
import Vue from 'vue'
import QS from 'qs'  
import axios from "axios";

//创建 课程
Vue.prototype.zhiboad = function (parms) {
    return new Promise((resolve, reject) => {
     
         axios({
            url: 'api/blade-live/client/class/create?'+ QS.stringify(parms),
            method: 'post',
          
        }).then( res=> {
            resolve(res); //将得到的数据存储到Promise中
        }).catch(error=>{ reject(error);})

    })

}
 //打开样板间
Vue.prototype.zhiboybj = function (parms) {
    return new Promise((resolve, reject) => {
     
         axios({
            url: 'api/blade-live/client/room/room-jump?'+ QS.stringify(
                {...parms,appKey: "d93535d424c31e09c480927887d86d21",
                    platform: "web",
                    bizType: "class",
                }
            ),
            method: 'post',
          
        }).then( res=> {
            resolve(res); //将得到的数据存储到Promise中
        }).catch(error=>{ reject(error);})

    })

}






  